import React from "react"
import HomeWhatsmfImg from "../images/home-whatsmf-img@2x.png"
import LogoLightGrayEn from "../images/logo-light-gray-en.png"
import LogoLightGrayZh from "../images/logo-light-gray-zh.png"
import { FormattedMessage } from "react-intl"

import {
  IconAngular,
  IconChrome,
  IconCss,
  IconGit,
  IconGraphql,
  IconHtml5,
  IconJs,
  IconReact,
  IconSass,
  IconWebpack,
  IconVscode,
  IconVue,
  IconJD,
  IconPdd,
  IconTencent,
} from "../images/icons"

const Home = ({ langKey }) => {
  const LogoLightGray = langKey === "zh" ? LogoLightGrayZh : LogoLightGrayEn
  return (
    <>
      <section className="section tech-and-tools">
        <div className="container">
          <h1 className="title">
            <FormattedMessage id="home-tech-title" />
          </h1>
          <div className="icons columns">
            <div className="column">
              <img src={IconHtml5} alt="HTML" />
            </div>
            <div className="column">
              <img src={IconCss} alt="CSS" />
            </div>
            <div className="column">
              <img src={IconJs} alt="JS" />
            </div>
            <div className="column">
              <img src={IconVue} alt="Vue" />
            </div>
            <div className="column">
              <img src={IconReact} alt="React" />
            </div>
            <div className="column">
              <img src={IconAngular} alt="Angular" />
            </div>
          </div>
          <div className="icons columns">
            <div className="column">
              <img src={IconVscode} alt="VSCode" />
            </div>
            <div className="column">
              <img src={IconChrome} alt="Chrome" />
            </div>
            <div className="column">
              <img src={IconSass} alt="SCSS" />
            </div>
            <div className="column">
              <img src={IconWebpack} alt="Webpack" />
            </div>
            <div className="column">
              <img src={IconGit} alt="git" />
            </div>
            <div className="column">
              <img src={IconGraphql} alt="GraphQL" />
            </div>
          </div>
        </div>
      </section>
      <section className="section salaries">
        <div className="container">
          <h1 className="title">
            <FormattedMessage id="home-salary-title" />
          </h1>
          <p className="tips has-text-grey">
            <FormattedMessage id="home-salary-title-tip" />
          </p>
          <div className="cards columns">
            <div className="card-item column">
              <img className="card-icon" src={IconJD} alt="JD"></img>
              <h2 className="card-name">
                <FormattedMessage id="home-salary-item-jd-title" />
              </h2>
              <p className="salary">
                <FormattedMessage id="home-salary-item-jd-salary" />
              </p>
              <p className="card-info has-text-grey">
                <FormattedMessage id="home-salary-item-jd-info" />
              </p>
            </div>
            <div className="card-item column">
              <img className="card-icon" src={IconPdd} alt="JD"></img>
              <h2 className="card-name">
                <FormattedMessage id="home-salary-item-pdd-title" />
              </h2>
              <p className="salary">
                <FormattedMessage id="home-salary-item-pdd-salary" />
              </p>
              <p className="card-info has-text-grey">
                <FormattedMessage id="home-salary-item-pdd-info" />
              </p>
            </div>
            <div className="card-item column">
              <img className="card-icon" src={IconTencent} alt="JD"></img>
              <h2 className="card-name">
                <FormattedMessage id="home-salary-item-tencent-title" />
              </h2>
              <p className="salary">
                <FormattedMessage id="home-salary-item-tencent-salary" />
              </p>
              <p className="card-info has-text-grey">
                <FormattedMessage id="home-salary-item-tencent-info" />
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="section whatsmf">
        <div className="container">
          <h1 className="title has-text-centered">
            <FormattedMessage id="home-whatsmf-title" />
          </h1>
          <p className="tips has-text-centered has-text-grey">
            <FormattedMessage id="home-whatsmf-title-tip" />
          </p>
          <div className="whatsmf-card columns">
            <div
              className="column"
              style={{
                backgroundImage: `url(${HomeWhatsmfImg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <h2 className="title has-text-light">
                <img src={LogoLightGray} alt="mstfe" />
              </h2>
            </div>
            <div className="column has-background-info">
              <h2 className="title has-text-white">
                <FormattedMessage id="home-whatsmf-main-title" />
              </h2>
              <p className="main has-text-white has-text-weight-bold">
                <FormattedMessage id="home-whatsmf-main" />
              </p>
              <p className="info has-text-light">
                <FormattedMessage id="home-whatsmf-info" />
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Home
