import React from "react"
import Layout from "../layouts/zh"
import SEO from "../layouts/seo"
import Home from "../components/home"

const HomePage = props => {
  return (
    <Layout location={props.location} pageType="home">
      <SEO title="首页" lang="zh" />
      <Home langKey="zh"></Home>
    </Layout>
  )
}

export default HomePage
